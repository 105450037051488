import request from "../axios";

export function batchQueryGroupServerApi(groupId) {
    return request({
        url: "/group/server/query/batch",
        method: 'GET',
        params: {
            group_id: groupId
        }
    })
}

export function createGroupServerApi(groupId, gameId, sessionId, sid) {
    return request({
        url: "/group/server/create",
        method: 'POST',
        data: {
            groupId: groupId,
            gameId: gameId,
            sessionId: sessionId,
            sid: sid,
        }
    })
}

export function deleteGroupServerApi(id) {
    return request({
        url: "/group/server/delete",
        method: 'DELETE',
        params: {
            id: id
        }
    })
}

export function containGroupServerApi(gameId) {
    return request({
        url: "/group/server/contain",
        method: 'GET',
        params: {
            game_id: gameId
        }
    })
}